
.bkg-blue {
    background: #00344c;
}

.bkg-orange {
    background: #db7c33;
}

.carousel-item {
    height: 610px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    background: #00344c;

    .text {
        width: 50%;
        padding-right: 70px;
        padding-left: 70px;
        background: #00344c;
        color: white;
        height: 100%;
        font-size: 40px;
        font-style: italic;
        font-family: Arial, Helvetica, sans-serif;
        span {
            display: block;
            text-align: right;
            padding-right: 30px;
        }

        p.left {
            text-align: left;
           
            
        }

    
        &.centered-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            p{
                text-align: left;
            }
        }
    }

    & > img {
        display: block ;
        object-fit: cover;
        object-position: center;
        height: 100%;
        flex-grow: 1;



        &.img-contained {
            object-fit: contain;
        }

        &.mic-photo{
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            object-position: left;
        }
    }


}

.carousel-item .podcast-text{
    padding-right: 0;
}

.slide .carousel-item {
    padding: 22px 0px;
}

.carousel-item > .house-img{
    object-position: right;
}

.carousel-item > .display1{
    display: none;
}

.right-photo{
    width: 50%;
}

.indicator {
    cursor: pointer;
    display: block;
    height: 25px;
    width: 25px;
    border-radius: 50px;
    background: white;
    box-shadow: 1px 1px 0px 1px #000000;
    margin-left: 10px;

    &.selected {
        background: #d34d00;
    }
}

.carousel .control-dots {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    bottom: 25px;
    right: 75px;
}

.home-screen {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    // .col-2 div:first-child {
    //     height: 550px;
    // }

    // .col-2 div:last-child {
    //     padding-right: 160px;
    // }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        color: #00344c;
        height: 80%;

        h4 {
            margin: 0;
            font-size: 52px;
            font-weight: bold;
            line-height: 70px;
            margin-bottom: 32px;
            text-align: left;
        }

        p {
            margin: 0;
            font-size: 22px;
            line-height: 35px;
            text-align: left;
            font-style: italic;
            margin-bottom: 35px;
            
        }
        button {
            width: fit-content;
            align-self: center;
        }

        &.white {
            color: white;
        }
        
        &.width-775{
            width: 775px;
        }
    }
}

.col-2 {
    display: flex;
    justify-content: center;
    align-items: center;

}

.about-us {
    padding: 20px 0px;
    
    .content {
        h4 {
        text-align: center;
        font-size: 60px;
        }
    }
     img {
        height: 350px;
    }


}

.business-call {
    padding: 38px 0px;

    .col-2 {
        align-items: stretch;

        img {
            height: 100%;
            object-fit: cover;
        }
    }
    
    .col-2 div:first-child {
        padding-left: 0px;
        height: auto;
        flex: 1;
    }

    .col-2 div:last-child {
        padding: 150px 180px 183px 80px;
    }
}

.strategic-podcast {

    .col-2 {
        align-items: stretch;
        padding: 70px 250px;

        img {
            display: block;
            object-fit: cover;
            object-position: center;
            // -webkit-transform: scaleX(-1);
            // transform: scaleX(-1);
            height: 100%;
          
        }
        div:first-child {
            padding: 148px 90px 185px 160px;
        }
        
        div:last-child {
            height: auto;
            padding-right: 0;
            padding: 70px 0px;
        } 
        .content{
            padding-right: 100px;
        }

    }
}

.carousel-item .podcast-image{
    display: block;
    // object-fit: fill;
    object-position: center;
    height: 100%;
    flex-grow: 1;
}

.strategic-blog {
    padding: 80px 0px;

    .col-2 {
        align-items: stretch;
        div:first-child {
            padding: 0;
            height: auto;

            img {
                height: 100%;
                object-fit: cover;
                object-position: 0px center;
            }
        }

        div:last-child {
            padding: 153px 160px 180px 65px;
        }
    }
}

.custom-button.blue-button {
    background: #00344c;
}

.screen-books {
    color: white;
    padding: 20px;

    .content {
        // h4, p {
        //     margin-bottom: 30px;
        // }

        p {
            margin-bottom: 50px;
            font-style: normal;
        }
    }

    // padding: 1px 74px;

    .screen-books.toggle-section-wrapper.toggleBooks:nth-child(2){
        background: red;
        color: #000000;

    }

    .toggle-section-wrapper {
        display: flex;
        justify-content: center;
        align-items: stretch;

        .toggleBooks {
            width: 30%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            border: 2px solid white;


            .book-item {
                background: #00344c;
                font-size: 25px;
                font-weight: bold;
                height: 25%;
                width: 100%;
                border: 2px solid white;
                display: grid;
                place-content: center;
                cursor: pointer;
                user-select: none;

                &.selected {
                    background: #df7e36;
                }
            }
        }

        .toggleContent {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            color: black;
            background: white;
            padding: 50px 90px;
            padding-right: 70px;
            min-height: 455px;


            .text {
                font-size: 18px;
                line-height: 26px;
                margin-right: 30px;
                text-align: left;
                font-style: italic;
                color:#00334b;

                .custom-button {
                    margin-top: 40px;
                }

                a {
                    display: block;
                    text-align: center;
                }
            }

            img {
                max-width: 280px;
                min-width: 280px;
            }
        }
    }
}

.subscribe-screen {
    background: #eef8fc;
    padding: 60px 140px 110px 140px;

    h4 {
        font-size: 58px;
        line-height: 42px;
        color: black;
        font-weight: 700;
        margin: 0;
        margin-bottom: 20px;
    }

    p {
        font-size: 33px;
        line-height: 42px;
        text-align: center;
        margin: 0;
        margin-bottom: 75px;
    }

    .form-subscribe {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 45px;
    
        input {
            height: 70px;
            border: 1px solid black;
            border-radius: 0px;
            width: 48%;
            padding-left: 20px;
            font-size: 20px;

            &:first-child {
                margin-right: 30px;
            }
        }
    }
}

.social-screen {
    background: #00344c;
    padding: 150px 250px;
    
    .top-part {
        
        color: white;
        height: 50px;
        margin-bottom: 50px;
        
        h4 {
            margin: 0;
            font-size: 58px;
            line-height: 42px;
            font-weight: 700;
        }
    }



    .bottom-part {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 50px;
        width: 400px;
        margin: auto;

        a {
            display: grid;
            place-content: center;
            width:100px;
            height: 100px;
            background: white;
            border: 1px solid black;
            border-radius: 10px;

            img {
                width: 100%;
                max-width: 50px;
                max-height: 50px;
            }
        }
    }
}

.fit-link {
    width: fit-content;
    margin: 0 auto;
}

.home-screen .want-a-free{

    h4{
        margin: 0;
        font-size: 60px;
        font-weight: bold;
        line-height: 65px;
        margin-bottom: 28px;
        text-align: left;
    } 

    p{
        margin: 0;
        font-size: 30px;
        line-height: 46px;
        text-align: left;
        font-style: italic;
        margin-bottom: 40px;
}  
    }


    
.home-screen .estate-podcast{
    width: 50%;
    padding-left: 70px;
    padding-right: 106px;

  p {
    margin: 0;
    font-size: 22px;
    line-height: 35px;
    text-align: left;
    font-style: italic;
    margin-bottom: 35px;

  }
  h4 {
    margin: 0;
    font-size: 52px;
    font-weight: bold;
    line-height: 71px;
    margin-bottom: 32px;
    text-align: left;
  }

} 


.quick-reads .col-2{
    padding: 20px;
    height: 650px;

    .content {
        h4{
            margin: 0;
            font-size: 52px;
            font-weight: bold;
            line-height: 72px;
            margin-bottom: 50px;
            text-align: left;

    }
        p{
            margin: 0;
            font-size: 22px;
            line-height: 35px;
            text-align: left;
            font-style: italic;
            margin-bottom: 50px;

    }
}
}

.twitter-img{
    max-width: 80px !important; 
    max-height: 80px !important;
}  
    

//dimensions
.margin-20 {
    margin-bottom: 20px;
}

.padding-250{
    padding-left: 70px;
    padding-right: 70px;
}

.padding-right-70{
    padding-right: 70px;
}

.margin-left-125{
    margin-left: 125px;
}
.margin-right-125{

    margin-right: 125px;
}

.carousel .slide img {
width: 50%;
}

@media only screen and (min-width: 1400px){
    .slide .carousel-item {
        height: auto;
        padding: 20px 0px;
    }

    .carousel .slide img {
        width: 700px;
        height: 420px;
    }
    .slide .carousel-item .text {
        font-size: 35px;
    }
    .slide .carousel-item .text p {
        margin: 0px;
        margin-bottom: 20px;
    }


    .carousel-item > .display2{
            display: none;
        }

        .carousel-item > .display1 {
            display: block;
            object-position: center;
        }


}

@media only screen and (max-width: 1365px) {

    .slide .carousel-item {
        padding: 22px 0px;
    }

    .carousel-item {
        .text {
            width: 50%;
            font-size: 28px;
            line-height: 50px;

        }

        // img {
        //     max-width: calc(100% - 300px);
        //     height: auto;
        // }
    }

    .home-screen {

        .col-2 {
            // div:last-child {
            //     padding-right: 60px;
            // }
        }
    }

    .business-call {
        padding: 50px 0px;
        .col-2 div:last-child {
            padding: 80px 60px 110px 40px;
        }
    }

    .strategic-podcast .col-2 { 
        padding: 20px 0px;
        height: 600px;
        div:first-child {
            padding: 35px 50px 60px 60px;
        }

        div:last-child {
            
        }
    }

    .strategic-blog {
        padding: 50px 0px;
        .col-2 div:last-child {
            padding: 80px 60px 110px 40px;
        }
    }

    .social-screen .bottom-part a {
        width: 100%;
        height: auto;
        padding: 30px;
    }

    .indicator {
        width: 20px;
        height: 20px;
    }

    // .social-screen .bottom-part {
    //     margin-top: -120px;
    // }
    // .about-us {
    //     padding: 70px 0px;
    // }
    
    .carousel-item .text.centered-content {
        font-size: 32px;
        padding-right: 60px;
        padding-left: 70px;
        p {
            text-align: left;
        }
    }   

    .padding-250 {
        padding-left: 70px;
        padding-right: 70px;
    }

    .social-screen {
        padding: 80px;
    }

    .about-us img {
        height: 350px;
    }
    
   .home-screen .want-a-free , 
   .about-us .content , 
   .home-screen .content , 
   .home-screen .estate-podcast , 
    .home-screen .estate-podcast ,
    .home-screen .estate-podcast ,
    .social-screen .top-part,
    .quick-reads .col-2 .content  { 

        h4 {
        font-size: 48px;
        }
        p {
            font-size:  22px;
        }
    }

    .margin-right-125 {
        margin-right: 100px;    
        }

    .screen-books .toggle-section-wrapper {

        .toggleContent{
            min-height: 455px;
        }
    }

    .screen-books .content p {
        margin-bottom: 30px;
    }

.strategic-podcast .col-2 .content {
    margin-left: 70px;
    margin-right: 50px;
    justify-content: center;
}

}

@media only screen and (max-width: 1200px) {
    .carousel-item{
        .text{
            line-height: 38px;
        }
        .text.centered-content {
            font-size: 30px;
        }

        
    }

    .screen-books .toggle-section-wrapper
    {
         .toggleBooks .book-item{
            font-size: 20px;

         }

       .toggleContent .text {
        font-size: 22px;
    } 
    } 

    .about-us img {
        height: 300px;
    }

    .margin-right-125 {
    margin-right: 80px;    
    }

    .screen-books .toggle-section-wrapper {
        .toggleBooks .book-item ,.toggleContent .text {
        // font-size: 23px;
        line-height: 30px;
    }

    .toggleContent img {
        max-width: 250px;
        min-width: 250px;
    }
    .toggleContent {
        min-height: 440px;
        padding: 20px 50px;
    }

    }

    .home-screen .want-a-free , 
    .about-us .content , 
    .home-screen .content , 
    .home-screen .estate-podcast , 
     .home-screen .estate-podcast ,
     .home-screen .estate-podcast ,
     .social-screen .top-part,
     .quick-reads .col-2 .content  { 
         h4 {
            line-height: 56px;
            margin-bottom: 25px;
            font-size: 43px;
         }
 
         p {
             font-size:  23px;
             line-height: 36px;
         }
     }

     .social-screen {
        padding: 50px;

        .top-part {
            margin-bottom: 32px;
        }
    }

    .home-screen .estate-podcast {
        //  padding-left: 0px;
    }

      
}

@media only screen and (max-width: 1100px) {

    .margin-left-125 {
        margin-left: 100px;
    }

    .carousel-item {
        .text {
            p.left{
                // width: 400px;
                padding-right: 20px;
                font-size: 30px;
            }
        }
    }
    .screen-books .content p {
        margin-bottom: 35px;
    }

    .home-screen .want-a-free , 
    .about-us .content , 
    .home-screen .content , 
    .home-screen .estate-podcast , 
     .home-screen .estate-podcast ,
     .home-screen .estate-podcast ,
     .social-screen .top-part,
     .quick-reads .col-2 .content  { 
         h4 {
            line-height: 50px;
            font-size: 38px;
         }
 
         p {
             font-size:  20px;
             line-height: 30px;
             margin-bottom: 20px;
         }
     }

     .home-screen .estate-podcast {
        
        padding-right: 53px;
     }

     .carousel-item .text.centered-content {
        padding-right: 30px;
        padding-left: 70px;
     }
}

@media only screen and  (max-width: 980px) {
    // .screen-books .toggle-section-wrapper .toggleContent {
    //     padding: 35px 20px 20px 25px;
    //     flex-direction: column-reverse;
    //     .text, img {
    //         margin: 0;
    //     }
    // }

    .carousel-item {
        height: 573px;
    }

    .margin-left-125 {
        margin-left: 80px;
    }

    .padding-250 {
        padding-left: 60px;
        padding-right: 60px;
    }

    .carousel-item .text p.left {
        // width: 380px;
        font-size: 28px;
    }

    .margin-right-125 {
        margin-right: 50px;
    }

    .home-screen .want-a-free h4, .about-us .content h4, .home-screen .content h4, .home-screen .estate-podcast h4, .home-screen .estate-podcast h4, .home-screen .estate-podcast h4, .social-screen .top-part h4, .quick-reads .col-2 .content h4 {
    line-height: 50px;
    font-size: 37px;
}
}

@media only screen and (max-width: 912px) {
    .carousel-item {
        .first-section {
            padding: 20px;
        }
        .text {
            font-size: 22px;
            line-height: 30px;
        }
        img {
             
        }
    }
    .home-screen .content h4 {
        font-size: 35px;
        line-height: 50px;
        margin-bottom: 30px;
    }

    .footer-wrapper .right-col #menu-footer li a, .footer-wrapper .right-col #menu-footer ul a {
        font-size: 12px;
    }

    .screen-books .toggle-section-wrapper .toggleContent .text {
        font-size: 18px;
        line-height: 26px;
    }

    .screen-books .toggle-section-wrapper .toggleContent .text .custom-button {
        margin-top: 25px;
    }

    
    // .social-screen .bottom-part {
    //     padding: 0px 90px 60px 90px;
    // }

    .carousel-item .text p.left {
        // width: 360px;
        font-size: 28px;
    }

    .home-screen .estate-podcast {
        padding-right: 36px;
    }

    .home-screen .content h4 {
        font-size: 33px;
    }
}

@media only screen and (max-width: 800px) {
    .carousel-item {
        height: 500px;
        padding: 80px 58px;
    }
    
    .carousel-item .text p.left {
        // width: 350px;
        font-size: 28px;
    }

    .home-screen .want-a-free , 
    .about-us .content , 
    .home-screen .content , 
    .home-screen .estate-podcast , 
     .home-screen .estate-podcast ,
     .home-screen .estate-podcast ,
     .social-screen .top-part,
     .quick-reads .col-2 .content  { 
         h4 {
            line-height: 40px;
            font-size: 30px;
         }
 
         p {
             font-size:  20px;
             line-height: 25px;
             margin-bottom: 20px;
         }
     }
}


@media only screen and (max-width: 768px) {
    
    .carousel-item {
        height: 470px;
        padding: 50px 40px;
    
    }
    .carousel-item .text p.left {
        // width: 350px;
        font-size: 25px;
    }

    .home-screen .col-2 div:last-child {
        
    padding-right: 22px;

    }

    .home-screen .estate-podcast {
        padding-right: 48px;
    }

    .strategic-podcast .col-2 div:last-child {
        padding-right: 0;
    }

    .screen-books .toggle-section-wrapper .toggleContent {
        min-height: unset;
    }

    .col-2 {
        flex-direction: column;

        & > div {
            width: 100%;
        }
    }

    .about-us {
        padding: 40px 20px;
        .col-2 div:first-child {
            padding: 0;
            margin: 0;
            padding-bottom: 20px;
        }
    }

    .business-call {
        padding: 0;
        padding-top: 40px;
        .col-2 div.content {
            padding: 40px 20px;
        }
    } 
    
    .screen-books {
        padding: 40px 20px;
        .content p {
            margin-bottom: 25px;
        }

        .toggle-section-wrapper {
            flex-direction: column;

            .toggleBooks {
                flex-direction: row;
                width: 100%;
                margin-bottom: 20px;

                .book-item {
                    width: 25%;
                    height: 90px;
                }
            }

            .toggleContent {
                margin-left: 0px;
            }
        }
    }

    .strategic-podcast .col-2 {
        padding-bottom: 40px;
        div.content {
            padding: 40px 20px;
            margin-left: 0px;
        }

        div:last-child {
            img {
                object-position: center center;
            }
        }
    }
    
    .strategic-blog {
        padding: 0;
        padding-top: 40px;

        .col-2 div.content {
            padding: 40px 20px;
        }
    }

    .social-screen {
        .top-part {

        }

        .bottom-part {
            padding-top: 0px;
            width: 359px;
             a { 
                padding: 22px;

            }

        }
    }

    .padding-250 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .margin-left-125 {
        margin-left: 30px;
    }

    .home-screen .want-a-free h4, .about-us .content h4, .home-screen .content h4, .home-screen .estate-podcast h4, .home-screen .estate-podcast h4, .home-screen .estate-podcast h4, .social-screen .top-part h4, .quick-reads .col-2 .content h4 {
        line-height: 33px;
        font-size: 27px;
        margin-bottom: 15px;
    }
    .home-screen .estate-podcast {
        padding-right: 20px;
    }

    .strategic-podcast .col-2 img {
        margin: auto;
    }

    .strategic-podcast .col-2 div.content {
        h4,p{
            text-align: center;
        }
    }
    
}

@media only screen and (max-width: 710px) {
    .carousel-item {
        padding: 47px 15px;
        .text p.left {
        
        // width: 300px;
        font-size: 25px;

        }
        .carousel-item .text span {
            font-size: 25px;
        }
    }

    .home-screen .content h4 {
        line-height: 35px;

    }
} 

@media only screen and (max-width: 670px) {

   .home-screen.bkg-blue.carousel-item.want-a-free{
    padding: 10px;

}
    .carousel-item{
        .want-a-free{
            padding: 10px;
    
        }
        .text {

        padding-right: 20px;
        p.left {
        // width: 270px;
        font-size: 23px;
        margin-bottom: 10px;
    }
    span {
        font-size: 23px;
        padding-right: 0px;
    }

    .margin-20 {
        margin-bottom: 10px;
    }

    } 

}
.carousel-item .text.centered-content {
    padding-right: 20px;
}

.home-screen .want-a-free h4, .about-us .content h4, .home-screen .content h4, .home-screen .estate-podcast h4, .home-screen .estate-podcast h4, .home-screen .estate-podcast h4, .social-screen .top-part h4, .quick-reads .col-2 .content h4 {
    font-size: 25px;
}
}



@media only screen and (max-width: 550px) {
    .home-screen .estate-podcast {
        width: 100%;
        padding: 20px 10px;
    }
    .carousel-item {
        height: 100%;
        flex-direction: column-reverse;
        padding: 0;

        .text {
            width: 100%;
            padding-bottom: 30px;
            padding-right: 10px;
            padding-left: 10px;

            p.left{
                width: auto;
                padding-right: 0px;
            }
        }

        img {
            width: 100% !important;
            max-width: unset;
            max-height: 300px;
        }
    }

    .carousel .control-dots {
        bottom: 5px;
        right: 25px;

        .indicator {
            width: 15px;
            height: 15px;
        }
    }



    .screen-books .toggle-section-wrapper .toggleBooks {
        flex-wrap: wrap;

        .book-item {
            width: 50%;
        }
    }

    .screen-books .toggle-section-wrapper .toggleContent {
        flex-direction: column-reverse;
        padding: 20px;
        .text {
            margin-top: 20px;
            margin-right: 0;
        }
        img {
            margin: 0;
            min-width: unset;
            max-width: 100%;
        }
    }

    .social-screen {
        .top-part {
            h4 {
                font-size: 30px;
                line-height: 48px;
            }
        }
        .bottom-part {
            width: 300px;

            a {
                padding: 15px;
            }
        }
    }

    .about-us{
        padding: 20px 10px;
    }

    .home-screen .col-2 div:last-child {
        padding-right: 0px;
    }

    .margin-left-125 {
        margin-left: 0px;
    }

    .strategic-podcast .col-2 {
        padding: 0;

        img {
            width: 100% !important;
            max-width: unset;
            max-height: 300px;
        }
    }



}

@media only screen and (max-width: 450px) {
    .social-screen  {
        padding: 20px;
        

        .top-part {
            margin-bottom: 10px; 
            
            h4{
            font-size: 27px;
        }
        }
        
       

    }

    .social-screen .bottom-part {
        gap: 45px;
        width: 272px;
    }
}

@media only screen and (max-width: 360px) {
    .screen-books .toggle-section-wrapper .toggleBooks .book-item {
        width: 100%;
    }
}